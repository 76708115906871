import Header from "./header";
import Footer from "./footer";
export default function EcoTourism(){
         return(
                 <>
                 <Header/>
                   <h1>This Page is Under Construction</h1>
                   <Footer/>               
                 
                 </>
         );
}